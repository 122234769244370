<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-xs-12 col-md-4">
                    <ul class="list-group">
                        <li @click="editable = translation" class="list-group-item"
                            v-for="(translation, index) in notification.translations" :key="translation.id">
                            {{ translation.language.name }}
                        </li>
                    </ul>

                    <form @submit.prevent="addMore">
                        <select class="form-control" v-model="language">
                            <option v-for="language in languages" :value="language">
                                {{ language.label }}
                            </option>
                        </select>
                        <button class="btn btn-primary">{{ $t("Add more") }}</button>
                    </form>
                </div>

                <div class="col-xs-12 col-md-8" v-if="editable">

                    <div v-if="notification.event && notification.event.params" class="mb-3">
                        <span v-for="(param, key) in notification.event.params" :key="key">
                            <span v-for="subParam in param" :key="subParam">
                                <span class="mr-2"
                                      v-html="'{{'+key.toUpperCase()+'.'+subParam.toUpperCase()+'}}'"></span>
                            </span>
                        </span>
                    </div>

                    <form @submit.prevent="save">
                        <div class="form-group">
                            <label>{{ $t('Title') }} ({{ editable.language.name }})</label>
                            <input v-model="editable.title" type="text" class="form-control" required>
                            <div v-if="errors['title']" v-for="error in errors['title']" :key="error"
                                 class="text-danger">{{ error }}
                            </div>
                        </div>

                        <div class="form-group">
                            <label>{{ $t('Subject') }}</label>
                            <input v-model="editable.subject" class="form-control" type="text" required>
                            <div v-if="errors['subject']" v-for="error in errors['subject']" :key="error"
                                 class="text-danger">{{ error }}
                            </div>
                        </div>

                        <div class="form-group">
                            <label>{{ $t('Body') }}</label>
                            <textarea v-model="editable.body" class="form-control" type="text"></textarea>
                            <div v-if="errors['body']" v-for="error in errors['body']" :key="error" class="text-danger">
                                {{ error }}
                            </div>
                        </div>

                        <div class="form-group">
                            <label>{{ $t('Text') }}</label>
                            <textarea v-model="editable.text" class="form-control" type="text"></textarea>
                            <div v-if="errors['text']" v-for="error in errors['text']" :key="error" class="text-danger">
                                {{ error }}
                            </div>
                        </div>
                        <button class="btn btn-primary">{{ $t("Submit") }}</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {notificationService} from '@/services/settings/notificationService'

export default {
    name: "ViewNotification",
    data() {
        return {
            notification: {},
            editable: {},
            language: null,

        }
    },
    computed: {
        id() {
            return this.$route.query.id
        },
        languages() {
            return this.$store.getters.languages
        }
    },
    created() {
        this.getNotification();
    },
    methods: {
        getNotification() {
            notificationService.show(this.id).then(response => {
                this.notification = response.data.data;
                this.editable = this.notification.translations[0];
            })
        },
        save() {
            let post = this.editable;
            delete post.language;

            notificationService.update(this.id, post).then(response => {
                // hmm
            })
        },
        addMore(language) {
            let newEditable = Object.assign({}, this.editable);
            newEditable.language_id = this.language.value;
            newEditable.language = this.language;
            newEditable.language.name = this.language.label;

            this.notification.translations.push(newEditable);
            this.editable = newEditable;
        },
    }
}
</script>