import axios from '@/axios';

class NotificationService {
    index(query) {
        return axios.get(`/settings/notifications`, {
            params: query
        });
    }
    show(id) {
        return axios.get(`/settings/notifications/${id}`);
    }
    update(id, data) {
        return axios.put(`/settings/notifications/${id}`, data);
    }
    status(id, data) {
        return axios.put(`/settings/notifications/${id}/status`, data);
    }
}

export const notificationService = new NotificationService();